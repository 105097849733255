import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import QAResponseModal from "../modals/QAResponseModal";
import { toast, ToastContainer } from "react-toastify";

interface Response {
  response: string;
  username: string;
  created_at: string;
}

export interface QuestionData {
  question_id: number;
  question: string;
  responses: Response[];
}

const AllQuestions: React.FC = () => {
  const auth = useAuth();

  const [questions, setQuestions] = useState<QuestionData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalQuestion, setModalQuestion] = useState<QuestionData | null>(null);
  const [modalUsername, setModalUsername] = useState<string>("");

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const apiUrl =
          process.env.NODE_ENV === "production"
            ? `${process.env.REACT_APP_API_BASE_URL_PROD}/api/questions`
            : `${process.env.REACT_APP_API_BASE_URL_DEV}/api/questions`;

        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data: QuestionData[] = await response.json();
        setQuestions(data);
      } catch (error) {
        console.error("Error fetching questions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, []);

  const filteredQuestions = questions.filter((q) =>
    q.question.toLowerCase().includes(search.toLowerCase())
  );

  function isUserResponse(response: Response): boolean {
    switch (response.username) {
      case "slayer4229":
        return (
          response.username === "slayer4229" && auth.username === "DoomSlayer"
        );
      case "thenunya":
        return response.username === "thenunya" && auth.username === "Nunya";
      default:
        return false;
    }
  }

  function submitResponse(response: string) {
    if (!modalQuestion) {
      toast.error("Failed to submit response. Please try again.");
      return;
    }

    const apiUrl =
      process.env.NODE_ENV === "production"
        ? `${process.env.REACT_APP_API_BASE_URL_PROD}/api/questions/${modalQuestion.question_id}`
        : `${process.env.REACT_APP_API_BASE_URL_DEV}/api/questions/${modalQuestion.question_id}`;

    fetch(apiUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        response: response,
        username: modalUsername,
        questionId: modalQuestion.question_id,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Response submitted successfully:", data);
        setIsModalOpen(false);
        setModalUsername("");
        setModalQuestion(null);
        setQuestions((prev) =>
          prev.map((q) => {
            if (q.question_id === modalQuestion.question_id) {
              q.responses = data;
            }
            return q;
          })
        );
        toast.success("Response submitted successfully!");
      })
      .catch((error) => {
        console.error("Error submitting response:", error);
        toast.error("Failed to submit response. Please try again.");
      });
  }

  return (
    <div className="bg-zinc-950 text-white min-h-screen p-6">
      <h2 className="text-3xl font-bold text-center text-red-500 mb-6">
        All Q&A
      </h2>

      <div className="flex justify-center mb-6">
        <input
          type="text"
          placeholder="Search questions..."
          className="w-full md:w-1/2 p-2 text-black rounded-lg border border-gray-500"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      {loading ? (
        <p className="text-center text-gray-400">Loading...</p>
      ) : filteredQuestions.length === 0 ? (
        <p className="text-center text-gray-400">No questions found.</p>
      ) : (
        <div className="space-y-6">
          {filteredQuestions.map((question) => {
            // If someone hasn't responded to the question, add an empty response
            if (question.responses.length < 2) {
              let responses: Response[] = [];
              const usernames = ["slayer4229", "thenunya"];
              for (const username of usernames) {
                const userResponses = question.responses.filter(
                  (res) => res.username.toLowerCase() === username
                );
                if (userResponses.length > 0) {
                  responses.push(userResponses[0]);
                } else {
                  // If either one of us hasn't responded, add an empty response
                  responses.push({
                    created_at: "",
                    username,
                    response: "",
                  });
                }
              }
              question.responses = responses;
            }
            return (
              <div
                key={question.question_id}
                className="bg-zinc-800 p-6 rounded-lg shadow-lg"
              >
                <h3 className="text-xl font-semibold text-red-400">
                  {question.question}
                </h3>
                <div className="mt-4 space-y-4">
                  {question.responses.length > 0 ? (
                    question.responses.map((res, index) => (
                      <div
                        key={index}
                        className="bg-zinc-700 p-4 rounded-lg shadow-sm"
                      >
                        {res.response === "" && isUserResponse(res) ? (
                          <span className="text-zinc-200">
                            You haven't responded yet.{" "}
                            <span
                              className="underline underline-offset-2 decoration-red-400 cursor-pointer"
                              onClick={() => {
                                setModalQuestion(question);
                                setModalUsername(res.username);
                                setIsModalOpen(true);
                              }}
                            >
                              Click here
                            </span>{" "}
                            to add your response.
                          </span>
                        ) : res.response === "" && !isUserResponse(res) ? (
                          <p className="text-zinc-200">
                            "{auth.username} hasn't responded yet."
                          </p>
                        ) : (
                          <p className="text-zinc-200">"{res.response}"</p>
                        )}

                        <div className="mt-2 text-sm text-zinc-400 flex justify-between">
                          {res.response && (
                            <span className="font-medium">
                              - {res.username}
                            </span>
                          )}
                          {res.created_at && (
                            <span className="italic">
                              {new Date(res.created_at).toLocaleString()}
                            </span>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-400 italic">No responses yet.</p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
      {modalQuestion && (
        <QAResponseModal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          question={modalQuestion}
          onSubmit={(response: string) => {
            submitResponse(response);
          }}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default AllQuestions;
