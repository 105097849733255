import React, { useState } from "react";
import { Heart } from "lucide-react";
import confetti from "canvas-confetti";

const loveNotes = [
  "Good morning, Mister Doom! Time to conquer the day (and maybe some coffee too). ☕️🔥",
  "You're the sunshine to my morning and the doom to my heart. 🌞💀",
  "If mornings were people, they'd be jealous of how amazing you are. 😎✨",
  "Here's a hug in text form to start your day. 🤗🌻",
  "Love you, Mister Doom! 🌼❤️",
  "You make the morning brighter than the sun. ☀️🌟",
  "Life is better with a little bit of doom and a whole lot of you, Senpai! 💛💀",
  "Another day, another chance to be amazed by you. Keep up your hard work baby! 🌅💫"
];

const FloatingHearts: React.FC = () => {
  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none">
      {[...Array(6)].map((_, i) => (
        <Heart
          key={i}
          className="absolute text-yellow-300/20"
          style={{
            left: `${15 + i * 15}%`,
            top: "-20px",
            animation: `float ${6 + i}s ease-in-out infinite`,
            animationDelay: `${i * 0.5}s`,
            transform: "translateY(0)",
          }}
          size={24 + i * 4}
        />
      ))}
    </div>
  );
};

const MorningSurprise: React.FC = () => {
  const [step, setStep] = useState(0);

  const handleClick = () => {
    if (step < loveNotes.length - 1) {
      setStep(step + 1);
    } else {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
        colors: ["#facc15", "#fef3c7", "#fde047"]
      });
    }
  };

  const handleReset = () => {
    setStep(0);
  };

  return (
    <div className="relative flex flex-col justify-center items-center bg-black px-6 min-h-screen overflow-hidden text-yellow-300 text-center">
      <FloatingHearts />

      <div className="mb-2 text-5xl animate-bounce">🌙💛💀</div>

      <h1 className="mb-8 font-bold text-3xl md:text-6xl">Good Morning, Mister Doom!</h1>

      <p className="mb-8 text-lg md:text-2xl">
        Click to reveal a morning smile...
      </p>

      <div className="bg-yellow-300/10 backdrop-blur-sm mb-8 px-8 py-6 rounded-2xl w-full max-w-2xl hover:scale-105 transition-all duration-500 transform">
        <p className="font-medium text-xl md:text-3xl italic">
          "{loveNotes[step]}"
        </p>
      </div>

      <div className="flex sm:flex-row flex-col gap-4">
        <button
          onClick={handleClick}
          className="bg-yellow-400 hover:bg-yellow-500 shadow-lg px-8 py-4 rounded-full text-black text-xl hover:scale-105 active:scale-95 transition-all duration-200 transform"
        >
          {step < loveNotes.length - 1 ? "Next Sweet Note 💛" : "Celebrate the Day! 🎉"}
        </button>

        <button
          onClick={handleReset}
          className="bg-gray-800 hover:bg-gray-700 shadow-lg px-6 py-4 rounded-full text-yellow-300 text-lg hover:scale-105 active:scale-95 transition-all duration-200 transform"
        >
          Reset 🔄
        </button>
      </div>

      <style>{`
        @keyframes float {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(100px); }
        }
      `}</style>
    </div>
  );
};

export default MorningSurprise;
