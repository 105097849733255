import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Textarea,
  FormControl,
  FormLabel,
  IconButton,
  Text,
  VStack,
  SimpleGrid,
} from "@chakra-ui/react";
import { X } from "lucide-react";
import { QuestionData } from "../pages/AllQuestions";

interface QAResponseModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: (response: string) => void;
  question: QuestionData;
}

const QAResponseModal: React.FC<QAResponseModalProps> = ({
  isOpen,
  onRequestClose,
  onSubmit,
  question,
}) => {
  const [response, setResponse] = useState("");

  return (
    <Modal
      isOpen={isOpen}
      onClose={onRequestClose}
      isCentered
      size={{ base: "md", md: "lg", lg: "xl" }}
    >
      <ModalOverlay />
      <ModalContent bgColor="#121212" color="#e0e0e0" p={{ base: 4, md: 6 }}>
        <ModalHeader
          color="#ff5252"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          Add a Response
          <IconButton
            icon={<X />}
            onClick={onRequestClose}
            aria-label="Close modal"
            backgroundColor="transparent"
            color="#A0AEC0"
            _hover={{ color: "#ff5252" }}
          />
        </ModalHeader>
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <FormControl>
              <FormLabel>Question</FormLabel>

              <Text fontSize="lg" color="#b0bec5" textAlign="left">
                {question.question}
              </Text>
            </FormControl>
            <SimpleGrid columns={{ base: 1 }} spacing={4}>
              <FormControl>
                <FormLabel>Response</FormLabel>
                <Textarea
                  value={response}
                  onChange={(e) => setResponse(e.target.value)}
                  focusBorderColor="#ff5252"
                  bg="#1b1b1b"
                  maxLength={300}
                  h="180px"
                />
              </FormControl>
            </SimpleGrid>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onRequestClose}
            size="md"
            variant="ghost"
            color="#A0AEC0"
            _hover={{ color: "#ffffff" }}
            mr={3}
          >
            Cancel
          </Button>
          <Button
            onClick={() => onSubmit(response)}
            size="md"
            variant="solid"
            colorScheme="red"
          >
            Add Response
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default QAResponseModal;
